.scrollbar::-webkit-scrollbar {
    width: 8px;
}
.scrollbar::-webkit-scrollbar-thumb {
    background: #4A4E5B; /* 滚动条的颜色 */
    border-radius: 6px; /* 滚动条圆角 */
}
.scrollbar::-webkit-scrollbar-track {
    background: transparent; /* 滚动条轨道的背景色 */
    /* background: transparent; */
}
.scrollbar::-webkit-scrollbar-thumb:hover {
    background: #fff;
}


@keyframes animation-skeleton {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0.4;
    }
    100% {
        opacity: 1;
    }
}
.skeleton-style {
    animation: 2s ease-in-out 0.5s infinite normal none running animation-skeleton;
    background-color: rgba(255, 255, 255, 0.13);
}

@keyframes rotateAnimation {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  
  /* 应用动画到元素 */
  .rotate-animation {
    animation: rotateAnimation 1s linear infinite;
  }

.echarts-tip {
    padding: 0 !important;
    border: none !important;
    background-color: transparent !important;
    box-shadow: none !important;
}

.ellipsis {
    white-space: nowrap;          /* 防止换行 */
    overflow: hidden;             /* 隐藏超出部分 */
    text-overflow: ellipsis;      /* 添加省略号 */
}
  