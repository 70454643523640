@import "./var.scss";
// 自定义样式优先级需要比Mui的高
.MuiButtonBase-root {
  &.bq-btn {
    font-size: 16px;
    border-radius: 20px;
    height: 44px;
  }
  &.bq-btn1 {
    font-size: 16px;
    height: 38px;
    border-radius: 20px;
  }
  &.bq-btn2 {
    font-size: 14px;
    height: 38px;
    border-radius: 20px;
  }
  &.bq-btn3 {
    font-size: 16px;
    height: 56px;
    border-radius: 22px;
  }
  &.purple-outlined-btn {
    color: #b028f9;
    border-color: #b028f9;
    &:hover {
      color: rgba(176, 40, 249, 0.8);
      border-color: rgba(176, 40, 249, 0.8);
    }
  }
}
.bq-textarea-input {
  box-sizing: border-box;
  min-height: 48px;
  min-width: 450px;
  max-width: 450px;
  font-family: "IBM Plex Sans", sans-serif;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  padding: 16px 16px;
  border-radius: 20px;
  color: #c7d0dd;
  background: #1c2025;
  border: 1px solid #5e5f62;
  // box-shadow: 0px 2px 2px #1c2025;

  &:hover {
    border-color: #3399ff;
  }

  &:focus {
    border-color: #3399ff;
    box-shadow: 0 0 0 1px #0072e5;
  }

  // firefox
  &:focus-visible {
    outline: 0;
  }
}
